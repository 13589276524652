import React, { FC, useEffect } from 'react';

import logoBlack from '../../../../assets/logo_black.svg';
import { useOperatorProfileStore } from '../../../../stores/useOperatorProfile';
import styles from './mainScreen.module.scss';

export const MainScreen: FC = () => {
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operatorName = useOperatorProfileStore((state) => state.operatorProfile.name);

  useEffect(() => {
    if (!operatorName) {
      getOperator();
    }
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainInfo}>
        <span className={styles.titleUser}>Hello {operatorName}</span>
        <div className={styles.title}>{`Ready for next call`}</div>
      </div>
      <img className={styles.logo} src={logoBlack} alt="logo" />
    </div>
  );
};
