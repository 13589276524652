import React, { FC, useState, useRef } from 'react';

import menu from '../../assets/menu.svg';
import microphone from '../../assets/microphone.svg';
import { useOnClickOutside } from '../../utils/hooks/useClickOutside';
import styles from './layout.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { WindowFocusHandler } from '../../utils/hooks/useWindowFocus';

type LayoutProps = {
  children?: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const logOut = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    navigate('/login');
  };

  const isAuth = localStorage.getItem('token') || sessionStorage.getItem('token');
  const micURL = 'https://mic.' + window.location.hostname;

  return (
    <div className={styles.container}>
      <header>
        <div className={`${styles.contentWrapper} ${styles.headerContent}`}>
          <div className={styles.infoWrapper}>
            <Link to={micURL} target="_blank" rel="noopener noreferrer">
              <img className={styles.microphone} src={microphone} alt="microphone" />
            </Link>
          </div>
          {isAuth && (
            <div
              ref={dropdownRef}
              onClick={() => setIsOpen(!isOpen)}
              className={styles.menuWrapper}
            >
              <img className={styles.menu} src={menu} alt="menu" />
              {isOpen && (
                <div onClick={logOut} className={styles.btnLog}>
                  Logout
                </div>
              )}
            </div>
          )}
        </div>
      </header>
      <section className={styles.main}>
        <div className={`${styles.contentWrapper} ${styles.mainContent}`}>{children}</div>
      </section>
      <div className={styles.footer} />
      <WindowFocusHandler />
    </div>
  );
};
