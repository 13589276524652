import cn from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';
import { Pixelify } from 'react-pixelify';
import loaderImage from '../../../../assets/loader.png';
import restart from '../../../../assets/restart.svg';
import unanimous from '../../../../assets/unanimous.jpg';
import warning from '../../../../assets/warning.svg';
import { Player } from '../../../../components/Player/Player';
import { useAgeInfoStore } from '../../../../stores/useAgeInfo';
import { useCallInfoStore } from '../../../../stores/useCallInfo';
import { EthnicityTaskData, useEthnicityInfoStore } from '../../../../stores/useEthnicityInfo';
import { useGenderInfoStore } from '../../../../stores/useGenderInfo';
import { useVoiceToFaceInfoStore } from '../../../../stores/useVoiceToFaceInfo';
import { ActionStatuses } from '../../../../utils/enum/actionStatuses';
import { TaskStatuses } from '../../../../utils/enum/taskStatuses';

import { period } from '../../../../utils/helpers/period';
import styles from './operationResultScreen.module.scss';

type ProgressBarProps = {
  result: ActionStatuses | '/';
  onRestart: () => void;
  pixelSize?: number;
};

const normalizeUrl = async (url: string) => {
  try {
    const response = await fetch(url);
    const imageBody = await response.blob();

    return imageBody;
  } catch (error) {
    console.error('error');
  }
};

const OperationResultScreen: FC<ProgressBarProps> = ({ result, onRestart, pixelSize = 10 }) => {
  const age = useAgeInfoStore((state) => state.task_data.age);
  const ageTaskStatus = useAgeInfoStore((state) => state.task_status);
  const image_url = useVoiceToFaceInfoStore((state) => state.task_data.image_url);
  const imgTaskStatus = useVoiceToFaceInfoStore((state) => state.task_status);
  const ethnicityData = useEthnicityInfoStore((state) => state.task_data);
  const ethnicityTaskStatus = useEthnicityInfoStore((state) => state.task_status);
  const clientName = useCallInfoStore((state) => state.call_data.client.name);
  const genderData = useGenderInfoStore((state) => state.task_data.gender);
  const genderTaskStatus = useGenderInfoStore((state) => state.task_status);

  const [normalImageUrl, setNormalImageUrl] = useState<string | null>(null);
  const [pixelationSize, setPixelSize] = useState(pixelSize);

  useEffect(() => {
    if (image_url) {
      if (!pixelSize || pixelationSize <= 0) return;

      const intervalId = setInterval(() => {
        setPixelSize(pixelationSize - 0.2);
      }, 50);
      return () => clearInterval(intervalId);
    }
  }, [pixelationSize, image_url]);

  useEffect(() => {
    if (image_url) {
      const getImage = async () => {
        const blobUrl = await normalizeUrl(image_url);
        const normalUrl = URL.createObjectURL(blobUrl as Blob);

        setNormalImageUrl(normalUrl);
      };

      getImage();
    }
  }, [image_url]);

  const ethnicityResult = (data: EthnicityTaskData) => {
    if (Object.prototype.hasOwnProperty.call(data, 'ethnicity') && data.ethnicity) {
      return data.ethnicity;
    }

    if (ethnicityTaskStatus === TaskStatuses.ERROR) {
      return '-';
    }

    return '?';
  };

  const ageResult = (age: string) => {
    if (age) {
      return period(+age);
    }

    if (ageTaskStatus === TaskStatuses.ERROR) {
      return '-';
    }

    return '?';
  };

  const genderResult = (gender: string) => {
    if (gender) {
      return gender;
    }

    if (genderTaskStatus === TaskStatuses.ERROR) {
      return '-';
    }

    return '?';
  };

  return (
    <div className={styles.resultFaceWrapper}>
      {(result === ActionStatuses.FRAUD_DETECTED ||
        result === ActionStatuses.NOT_VERIFIED ||
        result === ActionStatuses.VERIFIED ||
        result === ActionStatuses.TIMEOUT_EXCEEDED) && <Player verifyResult={result} />}
      <div className={styles.speechWrapper}>
        {result === ActionStatuses.FRAUD_DETECTED && (
          <div className={styles.speechContent}>
            <img src={warning} alt="warning" />
            <div className={styles.speechTitle}>Synthetic speech detected</div>
          </div>
        )}

        {result === ActionStatuses.FRAUD_DETECTED && <div className={styles.wr}></div>}
        <div
          className={cn(styles.imageWrapper)}
          style={
            result !== ActionStatuses.FRAUD_DETECTED
              ? {
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  alignItems: 'start',
                  width: '100%',
                  justifyContent: 'space-between',
                }
              : { display: 'flex' }
          }
        >
          <div className={styles.imageContainer}>
            {imgTaskStatus === TaskStatuses.FINISHED || imgTaskStatus === TaskStatuses.ERROR ? (
              !image_url ? (
                <img
                  style={{ border: '1px solid #C0C0C0' }}
                  src={unanimous}
                  width={93}
                  height={93}
                  alt="unanimous"
                />
              ) : (
                <Pixelify
                  src={normalImageUrl || unanimous}
                  width={93}
                  height={93}
                  centered={true}
                  pixelSize={pixelationSize}
                  fillTransparencyColor="white"
                />
              )
            ) : (
              <img className={styles.additionalLoader} src={loaderImage} alt="" />
            )}
          </div>
          <div>
            <div className={cn(styles.generatedAgeGenderText, styles.spacing)}>
              <div className={styles.contentTextWrapper}>
                <div className={styles.clientName}>{clientName || 'Name unknown'}</div>
              </div>
              <div className={styles.contentTextWrapper}>
                <div className={styles.titleWrapper}>Age</div>
                <div className={styles.titleContent}>{ageResult(age)}</div>
              </div>
              <div className={styles.contentTextWrapper}>
                <div className={styles.titleWrapper}>Origin</div>
                <div className={styles.titleContent}>
                  {ethnicityResult(ethnicityData as EthnicityTaskData)}
                </div>
              </div>
              <div className={styles.contentTextWrapper}>
                <div className={styles.titleWrapper}>Gender</div>
                <div className={styles.titleContent}>{genderResult(genderData)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(result === ActionStatuses.FRAUD_DETECTED ||
        result === ActionStatuses.TIMEOUT_EXCEEDED ||
        result === ActionStatuses.NOT_VERIFIED ||
        result === ActionStatuses.ENROLL_NOT_PASSED) && (
        <div className={styles.actionWrapper}>
          <div className={styles.actionContainer} onClick={onRestart}>
            <img src={restart} alt="restart" />
            <div className={styles.restartTitle}>Restart verification</div>
          </div>
          <div className={styles.actionContainer}></div>
          <div className={styles.actionContainer}></div>
        </div>
      )}
    </div>
  );
};

export default OperationResultScreen;
