import { create } from 'zustand';
import { getOperatorProfile } from '../services/operatorProfileApi';

export interface OperatorProfileData {
  dt_create: string;
  dt_last_login: string;
  dt_token_expire: string;
  dt_update: string;
  email: string;
  password: number | null;
  is_active: boolean;
  name: string;
  natural_operator_id: string;
  operator_id: string;
  role: string;
}

interface OperatorProfileStore {
  operatorProfile: OperatorProfileData;
  getProfile: () => void;
}

export const useOperatorProfileStore = create<OperatorProfileStore>((set) => ({
  operatorProfile: {
    dt_create: '',
    dt_last_login: '',
    dt_token_expire: '',
    dt_update: '',
    email: '',
    password: null,
    is_active: true,
    name: '',
    natural_operator_id: '',
    operator_id: '',
    role: '',
  },
  getProfile: async () => {
    const operatorProfile = await getOperatorProfile();
    set({ operatorProfile });
  },
}));
