import React, { FC } from 'react';
import success from '../../assets/sounds/success.wav';
import fraud from '../../assets/sounds/fraud.wav';
import notVerified from '../../assets/sounds/not-verified.wav';
import { ActionStatuses } from '../../utils/enum/actionStatuses';

const sounds: {
  [key: string]: string;
} = {
  [ActionStatuses.VERIFIED]: success,
  [ActionStatuses.FRAUD_DETECTED]: fraud,
  [ActionStatuses.NOT_VERIFIED]: notVerified,
  [ActionStatuses.TIMEOUT_EXCEEDED]: notVerified,
};

type PlayerProps = {
  verifyResult:
    | ActionStatuses.VERIFIED
    | ActionStatuses.NOT_VERIFIED
    | ActionStatuses.FRAUD_DETECTED
    | ActionStatuses.TIMEOUT_EXCEEDED;
};

export const Player: FC<PlayerProps> = ({ verifyResult }) => {
  return (
    <audio autoPlay>
      <source src={sounds[verifyResult]} />
    </audio>
  );
};
